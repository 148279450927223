@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../public/fonts/Roboto/Roboto-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../public/fonts/Roboto/Roboto-Medium.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Center-Light';
  src: url('../public/fonts/Signal/Signal-Center-Light.otf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

html {
  @apply font-roboto;
  @apply text-brand-gray-700;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  @apply text-brand-gray-700;
}

a:hover {
  @apply text-brand-blue-500;
}
